<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-card title="개정" :noHeader="true" class="cardClassDetailForm revisionLayout" topClass="topcolor-orange" v-if="Boolean(popupParam.mdmSwsId)">
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <div class="row">
                  <div class="col-2">
                    <c-text
                      :required="editable && isRevision"
                      :editable="editable && isRevision"
                      label="개정번호"
                      name="revisionNum"
                      v-model="data.revisionNum">
                    </c-text>
                  </div>
                  <div class="col-2">
                    <c-text
                      :editable="false"
                      label="개정일시"
                      name="regDtStr"
                      v-model="data.regDtStr">
                    </c-text>
                  </div>
                  <div class="col-2">
                    <c-text
                      :editable="false"
                      label="개정자"
                      name="regUserName"
                      v-model="data.regUserName">
                    </c-text>
                  </div>
                  <div class="col-6">
                    <c-text
                      :required="editable && isRevision"
                      :editable="editable && isRevision"
                      label="개정사유"
                      name="revisionContent"
                      v-model="data.revisionContent">
                    </c-text>
                  </div>
                </div>
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-card class="cardClassDetailForm" title="작업표준 상세">
            <template slot="card-select">
              <c-select
                :editable="!isRevision"
                v-if="popupParam.mdmSwsId"
                :comboItems="revdata"
                type="custom"
                typetext="개정이력"
                itemText="revisionNum"
                itemValue="mdmSwsId"
                name="selectedMdmSwsId"
                label=""
                v-model="selectedMdmSwsId"
                @input="rowClickRev"
              ></c-select>
            </template>
            <template slot="card-button">
              <q-btn-group outline >
                <c-btn
                  v-show="editable && Boolean(popupParam.mdmSwsId) && !isRevision"
                  label="개정"
                  icon="restart_alt"
                  @btnClicked="SetRevision" />
                <c-btn
                  v-show="editable && Boolean(popupParam.mdmSwsId) && isRevision"
                  label="개정취소"
                  icon="restart_alt"
                  @btnClicked="CancelRevision" />
                <c-btn
                  v-if="editable && Boolean(popupParam.mdmSwsId)"
                  label="삭제"
                  icon="remove"
                  @btnClicked="deleteSwsInfo" />
                <c-btn
                  v-if="editable"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="data"
                  :mappingType="mappingType"
                  label="저장"
                  icon="save"
                  @beforeAction="saveSwsInfo"
                  @btnCallback="saveCallback" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-text
                  :required="true"
                  :editable="editable"
                  label="안전작업표준 명"
                  name="swsName"
                  v-model="data.swsName">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-select
                  :required="true"
                  :editable="editable"
                  codeGroupCd="WORK_IMPORTANT_CD"
                  type="edit"
                  itemText="codeName"
                  itemValue="code"
                  name="workImportantCd"
                  label="작업중요도"
                  v-model="data.workImportantCd"
                ></c-select>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-plant
                  :required="true"
                  :editable="editable"
                  name="plantCd"
                  v-model="data.plantCd">
                </c-plant>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-datepicker
                  :editable="editable"
                  type="time"
                  label="작업예상시간"
                  name="termTm"
                  :minuteStep="10"
                  v-model="data.termTm"
                />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-checkbox
                  :editable="editable"
                  :isFlag="true"
                  label="안전작업허가서 발행필요"
                  name="swpFlag"
                  v-model="data.swpFlag"
                />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-checkbox
                  :editable="editable"
                  :isFlag="true"
                  label="사용여부"
                  name="useFlag"
                  v-model="data.useFlag"
                />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-10 col-lg-10 col-xl-10">
                <c-textarea
                  :editable="editable"
                  :rows="1"
                  label="작업 목적"
                  name="purpose"
                  v-model="data.purpose">
                </c-textarea>
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 tabvertical">
          <c-tab
            type="tabcard"
            :tabItems.sync="tabItems"
            :inlineLabel="true"
            v-model="tab"
            :dense="true"
            :height="tabHeight"
          >
            <template v-slot:default="tab">
              <component
                :key="tab.key"
                :is="tab.component"
                :param.sync="popupParam"
              />
            </template>
          </c-tab>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="padding-top:20px !important;">
          <c-upload 
            :attachInfo="attachInfo"
            :editable="editable"
            label="첨부파일">
          </c-upload>
        </div>
      </div>
    </q-form>
  </div>
</template>

<script>
import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'work-standard-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        mdmSwsId: '',
        plantCd: null,
        tabHeight: 0,
        updateFlag: '',
      }),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    contentHeight: null,
  },
  data() {
    return {
      tab: 'tab1',
      tabItems: [
        { name: 'tab1', icon: 'do_not_step', label: '작업단계', component: () => import(`${'./workStandardDetailStep.vue'}`) , key: uid()},
        { name: 'tab2', icon: 'add_location_alt', label: '설비/위치/자재', component: () => import(`${'./workStandardDetailEquip.vue'}`) , key: uid()},
        { name: 'tab3', icon: 'home_repair_service', label: '서비스/공구/작업자', component: () => import(`${'./workStandardDetailWork.vue'}`), key: uid() },
      ],
      saveUrl: transactionConfig.mdm.sws.job.insert.url,
      mappingType: 'POST',
      insertUrl: '',
      updateUrl: '',
      deleteUrl: '',
      revlistUrl: '',
      editable: true,
      isSave: false,
      isRevision: false,
      attachInfo: {
        isSubmit: '',
        taskClassCd: 'SWS_FILE',
        taskKey: '',
        beforeTaskKey: '',
        isRev: false,
      },
      revdata: [],
      selectedMdmSwsId: '',
      data: {
        mdmSwsId: '',  // 안전작업 표준 일련번호
        plantCd: null,  // 사업장코드
        swsNo: '',  // 안전작업 표준 관리번호
        swsName: '',  // 안전작업표준 명
        purpose: '',  // 목적
        workImportantCd: null,  // 작업중요도
        termTm: '',  // 작업예상시간
        swpFlag: 'N',  // 안전작업허가서 발행필요 여부
        useFlag: 'Y',  // 사용여부
        regUserId: '',  // 등록자 ID
        regUserName: '',  // 등록자 ID
        regDtStr: '',  // 등록일
        chgUserId: '',  // 수정자 ID
        sysRevision: 1,  // 시스템 개정번호
        groupId: '',  // 일련번호 키
        revisionNum: '',  // 개정번호
        revisionContent: '제정',  // 개정사유
        revisionFlag: 'N'
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    tabHeight() {
      return String(this.contentHeight - 530);
    },
  },
  watch: {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;

      this.detailUrl = selectConfig.mdm.sws.job.get.url;
      this.saveUrl = transactionConfig.mdm.sws.job.insert.url;
      this.deleteUrl = transactionConfig.mdm.sws.job.delete.url;
      this.revlistUrl = selectConfig.mdm.sws.job.get.url + '/revs';

      this.popupParam.tabHeight = this.contentHeight - 530
      this.getData();
    },
    getData() {
      this.isRevision = false;
      if (this.popupParam.mdmSwsId) {
        this.$set(this.attachInfo, 'taskKey', this.popupParam.mdmSwsId)
        this.getDetail(this.popupParam.mdmSwsId, true);
        this.selectedMdmSwsId = this.popupParam.mdmSwsId
      }
    },
    getDetail(_mdmSwsId, _check) {
      this.$http.url = this.detailUrl;
      this.$http.param = {mdmSwsId: _mdmSwsId}
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.data = _result.data;
        this.isRevision = false;
        this.data.revisionFlag = 'N';
        if (_check) {
          this.getRevList(_result.data.groupId);
        }
        if(_result.data.mdmSwsId == this.popupParam.mdmSwsId) {
          this.editable = this.$route.meta.editable;
          this.$set(this.popupParam, 'updateFlag', this.selectedMdmSwsId + '|false')
        } else {
          this.editable = false;
          this.$set(this.popupParam, 'updateFlag', this.selectedMdmSwsId + '|true')
        }
      },);
    },
    SetRevision() {
      this.$http.url = selectConfig.com.revision.getversion;
      this.$http.param = {revisionNum: this.data.revisionNum}
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.data.revisionNum = _result.data;
        this.data.revisionContent = '';
        this.data.regDtStr = '';
        this.data.regUserName = '';
        this.isRevision = true;
        /**
         * 첨부파일 셋팅
         */
        this.$set(this.attachInfo, 'taskKey', '')
        this.$set(this.attachInfo, 'beforeTaskKey', this.popupParam.mdmSwsId)
        this.$set(this.attachInfo, 'isRev', true)
      });
    },
    CancelRevision() {
      this.isRevision = false;
      /**
       * 첨부파일 셋팅
       */
      this.$set(this.attachInfo, 'taskKey', this.popupParam.mdmSwsId)
      this.$set(this.attachInfo, 'beforeTaskKey', '')
      this.$set(this.attachInfo, 'isRev', false)
      this.getData();
    },
    getRevList(_groupId) {
      this.$http.url = this.revlistUrl;
      this.$http.type = 'GET';
      this.$http.param = {groupId: _groupId};
      this.$http.request((_result) => {
        this.revdata = _result.data;
      },);
    },
    rowClickRev(row) {
      if (row) {
        if (row != this.data.mdmSwsId) {
          this.selectedMdmSwsId = row;
          this.getDetail(this.selectedMdmSwsId, false);
          this.$set(this.attachInfo, 'taskKey', this.selectedMdmSwsId)
        }
      }
    },
    saveSwsInfo() {
      if (this.popupParam.mdmSwsId) {
        this.saveUrl = transactionConfig.mdm.sws.job.update.url;
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = transactionConfig.mdm.sws.job.insert.url;
        this.mappingType = 'POST';
      }
      let saveMsg = '저장하시겠습니까?';
      if (this.isRevision) {
        saveMsg = '현재버전을 개정('+this.data.revisionNum+')하여 저장하시겠습니까?';
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: saveMsg,
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.data.regUserId = this.$store.getters.user.userId;
              this.data.chgUserId = this.$store.getters.user.userId;
              // 개정시
              if (this.isRevision) {
                this.saveUrl = transactionConfig.mdm.sws.job.insert.url;
                this.mappingType = 'POST';
                this.data.sysRevision = parseInt(this.data.sysRevision) + 1;
                this.data.revisionFlag = 'Y';
              }
              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.popupParam.mdmSwsId = result.data.mdmSwsId
      this.$set(this.attachInfo, 'taskKey', result.data.mdmSwsId)
      this.$set(this.attachInfo, 'isSubmit', uid())
      /**
       * 첨부파일 셋팅
       */
      this.$set(this.attachInfo, 'beforeTaskKey', '')
      this.$set(this.attachInfo, 'isRev', false)
      this.getData();
    },
    deleteSwsInfo() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '작업표준과 관련된 데이터가 모두 삭제됩니다.\r\n삭제하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.selectedMdmSwsId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            this.isdelete = !this.isdelete;
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup');
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    tabClick() {
      this.count++;
    }
  }
};
</script>